import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import '../styles/modules/ops-engineer.scss'
import TutorialTiles from '../tutorials/components/TutorialTiles'
import VideoTiles from '../videos/components/VideoTiles'
import VideoTilesLoader from '../components/loaders/VideoTilesLoader'

import opsDiagram from '../assets/images/ops-engineer/devops-hero-logo.png'

const OpsEngineer = ({ data }) => {
  const tutorials = data.tutorials.nodes
  const [videoData, setVideoData] = useState([])

  // Initial Effect that runs on page load
  useEffect(() => {
    const fetchVideos = async () => {
      let fetchUrl = `${process.env.GATSBY_API_URL}/v1/tags/videos?page=0&pageSize=6&tags=Ops`
      const result = await fetch(fetchUrl, { method: 'get', mode: 'cors' })
      const data = await result.json()
      setVideoData(data)
    }
    fetchVideos()
  }, [])

  return (
    <div className='page--ops-engineer flex flex-col'>
      <Helmet title={`Ops Engineer | Couchbase Developer Portal`} />

      <div className='appdev-red text-white'>
        <div className='wrapper pt-12 sm:pt-20 pb-8 sm:pb-24 grid grid-cols-1 sm:grid-cols-2 gap-12 place-items-center'>
          <div className='order-last sm:order-first'>
            <h2>Ops Engineer</h2>
            <p>
              Couchbase has powerful, robust, and flexible solutions to meet your needs. Our cloud-first DBaaS,
              <strong> Couchbase Capella</strong>, makes deploying solutions a breeze. Our Autonomous Operator provides full-stack
              integration of Couchbase Server and CNCF projects including Kubernetes, Prometheus, Helm, CNI, Core DNS,
              Operator Framework, and more.
            </p>
          </div>
          <div>
            <img src={opsDiagram} alt='dev-ops chart' className='float-right' />
          </div>
        </div>
      </div>

      <div className='bg-gray-230'>
        <div className='py-12 wrapper mx-auto text-center'>
          <h2>Products</h2>
          <p>Couchbase is a modern, multicloud-to-edge, JSON document database for building applications with agility
            without compromising performance and scale.</p>
          <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 py-4'>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Couchbase Capella</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Capella is the easiest and fastest way to get started with
                Couchbase. This fully managed database-as-a-service eliminates your database management efforts and
                reduces costs.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://www.couchbase.com/products/capella' target='_blank' rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold' alt="couchbase capella">Learn
                  More</a>
              </div>
            </div>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Autonomous Operator</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Couchbase Autonomous Operator enables DevOps teams to run,
                auto-scale, and manage Couchbase as a stateful database application next to their microservices
                applications on a Kubernetes platform, which also provides freedom from cloud vendor lock-in and
                supports hybrid and multicloud strategies.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://www.couchbase.com/products/cloud/kubernetes' target='_blank' rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold' alt="autonomous operator">Learn
                  More</a>
              </div>
            </div>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Couchbase Server</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Couchbase Server, our database server, manages and stores
                data in the cloud. It scales easily to billions of records and terabytes of data, supports millions of
                concurrent users, and provides 24x365 uptime.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://www.couchbase.com/products/server' target='_blank' rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold' alt='couchbase server'>Learn
                  More</a>
              </div>
            </div>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Sync Gateway</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Couchbase Mobile includes synchronization between
                Couchbase Lite and Couchbase Server, and peer-to-peer synchronization between Couchbase Lite instances.
                Synchronization is orchestrated by Sync Gateway, our secure gateway.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://www.couchbase.com/products/sync-gateway' target='_blank' rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold' alt='sync gateway'>Learn
                  More</a>
              </div>
            </div>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Couchbase Shell</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Provides useful tooling both during development and
                production operation. Doesn't matter if you need to generate fake data or check the status of your
                indexes - we got you covered.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://couchbase.sh/' target='_blank' rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold' alt='couchbase shell'>Learn
                  More</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='appdev-blue flex flex-row w-full justify-center text-white'>
        <div className='appdev-section flex justify-end grid grid-cols-1 sm:grid-cols-2 -ml-4'>
          <div className='code-bg-ops'></div>
          <div className='pt-8 mb-12'>
            <h2 className='text-right'>Learning Couchbase</h2>
            <p className='text-right'>Learn how to monitor Couchbase, integrate with power tools like Prometheus, how to
              interact with Couchbase Support and more.</p>
            <a href='/learn' target='_blank' rel='noopener noreferrer' className='cb-white-button-outline float-right no-underline' alt='learning paths'>Learning Paths</a>
          </div>
        </div>
      </div>

      <div className='bg-white'>
        <div className='py-12 wrapper mx-auto text-center'>
          <h2>Tutorials</h2>
          <p>Learn how to deploy and monitor Couchbase.</p>
          <div className='text-left'>
            <TutorialTiles tutorials={tutorials} showCategories={false} gridTilesContainerClass={'grid-tiles-container-blue-3col'}/>
          </div>
          <a href='/tutorials' target='_blank' rel='noopener noreferrer'  className='cb-black-button-large' alt='tutorials'>More Tutorials</a>
        </div>
      </div>

      <div className='bg-gray-230'>
        <div className='py-12 wrapper mx-auto text-center'>
          <h2>Videos</h2>
          <p>Couchbase videos from all of our Couchbase events.</p>
          <div className='text-left w-11/12 mx-auto'>
            {videoData.length > 1
              ? <VideoTiles videos={videoData} numColumns={3} showDescription={false} />
              : <VideoTilesLoader
                colsXS={2} colsSM={2} colsMD={3} colsLG={4} colsXL={4} cols2XL={4}
              />
            }
          </div>
          <a href='/videos' target='_blank' rel='noopener noreferrer'  className='cb-black-button-large' alt='more videos'>More Videos</a>
        </div>
      </div>

      <div className='bg-white'>
        <div className='py-12 wrapper mx-auto text-center'>
          <h2>Connectors</h2>
          <p>The connectors can be deployed in different modes depending on the requirements of your project.</p>
          <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 py-4'>
            <div className='card-gray'>
              <h3 className='divider-below-center divider-dark'>Elasticsearch Connector</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Documentation of the various ways to run the Couchbase
                Elasticsearch Connector, followed by step-by-step instructions for installing the connector in
                solo/distributed mode.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/elasticsearch-connector/current/getting-started.html' target='_blank'
                   rel='noopener noreferrer' alt='elasticsearch connector documentation'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Documentation</a>
              </div>
            </div>
            <div className='card-gray'>
              <h3 className='divider-below-center divider-dark'>Kafka Connector</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Documentation on deploying the Kafka Connector.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/kafka-connector/current/quickstart.html' target='_blank'
                   rel='noopener noreferrer' alt='kafka connector documentation'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Documentation</a>
              </div>
            </div>
            <div className='card-gray'>
              <h3 className='divider-below-center divider-dark'>Spark Connector</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>The Couchbase Spark Connector provides first-class
                integration between your high-performance Couchbase Server cluster and the Apache Spark data processing
                platform.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/spark-connector/current/getting-started.html' target='_blank' alt='spark connector documentation'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Documentation</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-blue-400 w-full justify-center py-10 text-white'>
        <div className='text-center wrapper'>
          <h2>Other Resources</h2>
          <p>
            Couchbase is an award-winning distributed NoSQL cloud database. It delivers unmatched versatility,
            performance, scalability, and financial value across cloud, on-premises, hybrid, distributed cloud, and edge
            computing deployments. The following links can provide you with quick access to resources to help you become more
            productive in your daily tasks.
          </p>
          <div className='grid grid-cols-1 sm:grid-cols-3 gap-6 my-8'>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Autonomous Operator</h3>
              <p className='text-left pb-10'>
                Documentation enabling DevOps teams to run, auto-scale, and manage Couchbase as a stateful database
                application next to their microservices applications on a Kubernetes platform.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/operator/current/overview.html' target='_blank' alt='autonomous operator documentation'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Documentation</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Couchbase Capella</h3>
              <p className='text-left pb-10'>
                Documentation on Couchbase Capella, which allows you to take the complexity out of deploying, managing,
                scaling, and securing Couchbase in the public cloud.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/cloud/index.html' target='_blank'
                   alt='couchbase capella documentation' rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Documentation</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Couchbase Server</h3>
              <p className='text-left pb-10'>
                Documentation on Couchbase Server, a modern, distributed document database with all the desired
                capabilities of a relational database and more.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/home/server.html' target='_blank' alt='couchbase server documentation'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Documentation</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Couchbase Shell</h3>
              <p className='text-left pb-10'>
                A modern shell to interact with Couchbase Server and Cloud, now available.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://couchbase.sh/docs/' target='_blank' alt='cochbase shell documentation'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Documentation</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Couchbase Service Broker</h3>
              <p className='text-left pb-10'>
                Documentation on Service brokers which implements the Open Service Broker API, an open standard for
                interoperability between clients and service brokers. Service brokers are an abstraction layer around a
                service that allow users to create instances of a service and bind applications to them.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/service-broker/current/index.html' target='_blank' alt='service broker documentation'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Documentation</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Deployment Examples</h3>
              <p className='text-left pb-10'>
                A GitHub repository with various helm chart examples on how to quickly deploy Couchbase using Autonomous
                Operator, Admission Controller, and a Couchbase Cluster.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://github.com/couchbase-partners/helm-charts' target='_blank' alt='github helm charts'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>GitHub</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default OpsEngineer


export const query = graphql`
  query tutorialsQueryDevops {
    tutorials: allMarkdownRemark(
      filter: {frontmatter: {content_type: {in: ["tutorial", "quickstart"]}, landing_page: {eq: "devops"}}}
      sort: {fields: [frontmatter___landing_order], order: ASC}
    ) {
      nodes {
        frontmatter {
          path
          content_type
          title
          technology
          filter
          description
          length
          tags
          landing_page
          landing_order
        }
        id
      }
    }
  }
`
