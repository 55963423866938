import React from 'react'
import { Link } from 'gatsby'

import defaultImage from '../../assets/images/videos/default.png'

const VideoTiles = ({ videos, numColumns, showDescription }) => {
  const truncate = (str, numWords) => {
    if (!str) return 'no description'
    let strSplit = str.split(" ").splice(0, numWords)
    let newString = strSplit.join(" ")
    if (strSplit.length < numWords) {
      return newString
    }
    return newString += '...'
  }

  return (
    <>
      <div className={`video-tiles-container ${numColumns === 3 ? 'video-tiles-3' : 'video-tiles-4'}`}>
        {videos.map((video) => {
          const videoPath = video.path.replace("/video/", "")
          return (
            <Link to={`/video?v=${videoPath}`} key={video.youtubeId} className={`inline-block ${!showDescription ? ' bg-gray-50' : ''}`}>
              <div className={`grid-tile-item gray ${video.youtubeId}`} key={video.youtubeId}>
                <div className="tile-thumbnail">
                  <img src={video.thumbnail ? video.thumbnail : defaultImage} alt={`Video: ${video.title}`} />
                </div>
                <div className="tile-item--title">
                  {video.eventTitle}
                </div>
                <div className="tile-item--title">
                  {video.title}
                </div>
                {!!showDescription &&
                  <>
                    <hr />
                    <div className="tile-item-description" >
                      {truncate(video.description, 24)}
                    </div>
                  </>
                }
              </div>
            </Link>
          )
        })}
      </div>
    </>
  )
}

export default VideoTiles
