import React from "react"
import ContentLoader from "react-content-loader"
import { useMediaPredicate } from 'react-media-hook'
//import useWindowSize from '../hooks/useWindowSize'

/*
  These pages use `react-content-loader`, you can find tutorials and source through these links:

  ## NPM
  https://www.npmjs.com/package/react-content-loader

  ## GitHub Readme
  https://github.com/danilowoz/react-content-loader#readme

  ## Use your own SVGs
  https://stackoverflow.com/questions/58997026/how-to-convert-react-content-loader-to-svg

  ##

  ## React Loading Placeholder – Skeleton Loading (Do it manually?)
  https://www.youtube.com/watch?v=-BX9zwye7tM

  ## React Loading Skeleton Component (Different Library)
  https://www.youtube.com/watch?v=HVQsQ3GXh-I
  https://www.youtube.com/watch?v=2DHer5AtyiU
*/

const VideoTilesLoader = (props) => {
  //const winSize = useWindowSize() // not used yet
  const { colsXS, colsSM, colsMD, colsLG, colsXL, cols2XL, ...otherProps } = props
    // Get values from props using destructuring {x, y, z, ...other}
  // const { rows, columns, coverHeight, coverWidth, padding, speed } = props

  const lt2XL = useMediaPredicate('(max-width: 1535px)')
  const ltXL =  useMediaPredicate('(max-width: 1279px)')
  const ltLG =  useMediaPredicate('(max-width: 1023px)')
  const ltMD =  useMediaPredicate('(max-width: 767px)')
  const ltSM =  useMediaPredicate('(max-width: 639px)')

  // Values for ContentLoader
  const rows = 2
  const columns = (!lt2XL)
    ? cols2XL
    : (lt2XL && !ltXL)
      ? colsXL
      : (ltXL && !ltLG)
        ? colsLG
        : (ltLG && !ltMD)
          ? colsMD
          : (ltMD && !ltSM) 
            ? colsSM 
            : ltSM
              ? colsXS : 1
  const coverHeight = 180
  const coverWidth = 200
  const padding = 16
  const speed = 1

  const coverHeightWithPadding = coverHeight + padding
  const coverWidthWithPadding = coverWidth + padding
  const initial = 35
  const covers = Array(columns * rows).fill(1)

  return (
    <ContentLoader
      speed={speed}
      width={columns * coverWidthWithPadding}
      height={
        rows * coverHeightWithPadding 
        + 46 // manually adding in some height...
      }
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...otherProps}
    >
      <rect
        x="0"
        y="0"
        rx="5"
        ry="5"
        // width={columns * coverWidthWithPadding - padding}
        width={240}
        height="20"
      />

      {covers.map((g, i) => {
        let vy = Math.floor(i / columns) * coverHeightWithPadding + initial
        let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding)
        return (
          <rect
            key={i}
            x={vx}
            y={vy}
            rx="5"
            ry="5"
            width={coverWidth}
            height={coverHeight}
          />
        )
      })}
    </ContentLoader>
  )
}

export default VideoTilesLoader
